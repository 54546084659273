import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element.js'
// 导入全局样式表
import './assets/css/global.css'
import common from './common.js'
import axios from 'axios'
import store from './store'
// 富文本
import QuillEditor from 'vue-quill-editor'
import '../node_modules/quill/dist/quill.core.css'
import '../node_modules/quill/dist/quill.snow.css'
import '../node_modules/quill/dist/quill.bubble.css'
Vue.use(QuillEditor)
// 配置请求根路径
// axios.defaults.baseURL = 'http://127.0.0.1:8888/api/private/v1/'
axios.defaults.baseURL = store.state.publicUrl
// 配置请求头
axios.interceptors.request.use(config => {
  config.headers.TOKEN = window.sessionStorage.getItem('token')
  // 最后必须return config
  return config
})
Vue.prototype.$http = axios
Vue.prototype.common = common
Vue.config.productionTip = false

var vm = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
// 添加响应拦截器
axios.interceptors.response.use(response => {
  const code = new Set([-13, -11, -12, -13, -14])
  if (code.has(response.data.rep_code)) {
    window.sessionStorage.clear()
    vm.$router.push('/login')
  }
  return response
}, error => {
  // 对响应错误做些什么
  console.log('Response Error:', error)
  // 可以统一处理错误提示
  Vue.prototype.$message.success('请检查网络状况')
  return Promise.reject(error)
}
)
